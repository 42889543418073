export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "cs": {
        "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stávající"])}
      },
      "de": {
        "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aktuell"])}
      },
      "es": {
        "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["actual"])}
      },
      "fr": {
        "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["actuel"])}
      },
      "id": {
        "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sekarang ini"])}
      },
      "it": {
        "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["attuale"])}
      },
      "ja": {
        "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在"])}
      },
      "sw": {
        "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sasa"])}
      },
      "zh-Hant": {
        "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目前"])}
      }
    }
  })
}
