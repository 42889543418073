export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "cs": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nápověda"])}
      },
      "de": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilfe"])}
      },
      "es": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayuda"])}
      },
      "fr": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aide"])}
      },
      "id": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bantuan"])}
      },
      "it": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aiuto"])}
      },
      "sw": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["msaada"])}
      },
      "zh-Hant": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["幫助"])}
      }
    }
  })
}
